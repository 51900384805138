import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import App from './_app';
import Hero from '../components/Hero';
import Article from '../components/Article';

const Template = ({ data }) => (
    <App>
        <Helmet>
            <title>{data.contentfulPage.title} | FlexStaf IT</title>
            <meta name="description" content={
                data.contentfulPage.description ?
                    data.contentfulPage.description.description :
                    'FlexStaf IT is a Canadian-based recruitment organization, specializing in short and long-term contract assignments, permanent placement positions and fully managed end-to-end IT project solutions.'
            }
            />
        </Helmet>
        <Hero
            title={data.contentfulPage.heading}
            subtitle={data.contentfulPage.lead ? data.contentfulPage.lead.lead : null}
            image={data.contentfulPage.image} />
        <Article>
            <div dangerouslySetInnerHTML={{
                __html: data.contentfulPage.childContentfulPageBodyRichTextNode.childContentfulRichText.html
            }} />
        </Article>
    </App>
);

export default Template;
export const query = graphql`
    query getPage($url: String!){
        contentfulPage(url: { eq: $url}) {
            title 
            description {
                description
            }
            heading
            lead {
                lead
            }
            url
            image {
                title
                fluid(maxWidth: 1200, quality: 100){
                    ...GatsbyContentfulFluid_withWebp
                }
            }
            childContentfulPageBodyRichTextNode {
                childContentfulRichText {
                    html
                }
            }
        }
    }
`;