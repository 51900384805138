import React from 'react';
import styled, { css } from 'styled-components';
import { ContainerMedium } from './Container';
import withMedia from './withMedia';

const ArticleWrapper = styled.article`
    background-color: #FFF;
    padding: 4rem 0;
    width: 100%;

    div {
        width: 80%;

        ${withMedia('tablet', css`
            width: 100%;
        `)}
    }
`;

const Article = props => (
    <ArticleWrapper>
        <ContainerMedium>
            {props.children}
        </ContainerMedium>
    </ArticleWrapper>
);

export default Article;